<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="会员昵称" prop="nickname">
                <el-input v-model="query.nickname" placeholder="请输入会员昵称" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="手机号" prop="phone" >
                <el-input v-model="query.phone" placeholder="请输入手机号" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="头像" align="center" prop="headImg">
                <template v-slot="slot">
                    <div v-if="slot.row.headImg == null || slot.row.headImg == ''">未上传图片</div>
                    <img :src="slot.row.headImg" width="80px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="会员昵称" align="center" prop="nickname" />
            <el-table-column label="手机号" align="center" prop="phone" />
            <el-table-column label="余额" align="center" prop="money" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.money).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="总收益" align="center" prop="saleMoney" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.saleMoney || 0).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="下单数" align="center" prop="orderNum" />
            <el-table-column label="总消费金额" align="center" prop="countPayMoney" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red;">￥{{ (scope.row.countPayMoney || 0).toFixed(2) }}</span>
                </template>
            </el-table-column>
            
            <!-- <el-table-column label="操作" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit"
                        @click="handleUpdate(scope.row)" v-hasPermi="['pms:spuInfo:edit']">修改</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete"
                        @click="handleDelete(scope.row)" v-hasPermi="['pms:spuInfo:remove']">删除</el-button>
                    <el-button v-show="scope.row.publishStatus == '0'" size="mini"
                        type="text" icon="el-icon-edit" @click="handleUpStatus(scope.row)"
                        v-hasPermi="['pms:spuInfo:upStatus']">上架</el-button>
                    <el-button v-show="scope.row.publishStatus == '1'" size="mini"
                        type="text" icon="el-icon-delete" @click="handleDownStatus(scope.row)"
                        v-hasPermi="['pms:spuInfo:upStatus']">下架</el-button>

                </template>
            </el-table-column> -->
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'VipCount',
    data() {
        return {
            // 列表
            list: [],
            // 类型
            dictType: [],
            // 总条数
            total: 0,
            // 遮罩层
            loading: true,
            // 查询
            query: {
                current: 1,
                size: 10,
                nickname: '',
                phone: ''
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取列表数据
        getList() {
            api.memberStatistics(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        // 重置
        reset() {
            this.query.current = 1
            this.query.nickname = '',
            this.query.phone = '',
            this.getList()
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
    }
}

</script>